<template>
    <div class="hello-ezuikit-js" style="display:flex;height:100%">
        <el-card style="width:15%" class="box-card">
            <div slot="header" class="clearfix">
                <img src="../../assets/surveillance.png" class="o-img" style="margin-right:2%" /><span>视频通道列表</span>
            </div>
            <el-tree :data="treeData" :props="defaultProps" @node-click="handleNodeClick">

            </el-tree>
        </el-card>
        <div id="video-container" style="float:right;width:800px;height:600px;margin:1%">

        </div>
        <el-card class="box-card" style="float:right;width:21%">
            <div slot="header" class="clearfix">
                <img src="../../assets/panel.png" class="o-img" style="margin-right:2%" /><span>云台操作面板</span>
            </div>
            <div style="margin:5% 0 0 0">
                <span style="margin-bottom:5px;margin-bottom:50px">当前操作通道：{{radioSelected}} 号摄像头</span>
            </div>
            <div style="width:100%;height:50%">
                <div style="width:100%;margin-bottom:5px">方向调整:</div>
                <div style="width:60%;margin:20% 0 20% 20%">
                    <el-button size='mini' circle class="button-d" @click="operation(4)"><img
                            src="../../assets/leftup.png" class="d-img" /></el-button>
                    <el-button size='mini' circle class="button-d" @click="operation(0)"><img src="../../assets/up.png"
                            class="d-img" /></el-button>
                    <el-button size='mini' circle class="button-d" @click="operation(6)"><img
                            src="../../assets/rightup.png" class="d-img" /></el-button>
                    <el-button size='mini' circle class="button-d" @click="operation(2)"><img
                            src="../../assets/left.png" class="d-img" /></el-button>
                    <el-button size='mini' circle class="button-d" @click="operation(-1)"><img
                            src="../../assets/stop.png" class="d-img" /></el-button>
                    <el-button size='mini' circle class="button-d" @click="operation(3)"><img
                            src="../../assets/right.png" class="d-img" /></el-button>
                    <el-button size='mini' circle class="button-d" @click="operation(5)"><img
                            src="../../assets/leftdown.png" class="d-img" /></el-button>
                    <el-button size='mini' circle class="button-d" @click="operation(1)"><img
                            src="../../assets/down.png" class="d-img" /></el-button>
                    <el-button size='mini' circle class="button-d" @click="operation(7)"><img
                            src="../../assets/rightdown.png" class="d-img" /></el-button>
                </div>
            </div>
            <div style="width:100%">
                <div style="width:100%">缩放调整:</div>
                <div style="width:100%;margin: 0 0 0 15%">
                    <el-button size='mini' circle class="button-o" style="margin-top:10px" @click="operation(8)"><img
                            src="../../assets/zoomin.png" class="o-img" /></el-button>
                    <el-button size='mini' circle class="button-o" @click="operation(-1)"><img
                            src="../../assets/stop.png" class="o-img" /></el-button>
                    <el-button size='mini' circle class="button-o" @click="operation(9)"><img
                            src="../../assets/zoomout.png" class="o-img" /></el-button>
                </div>
                <div style="width:100%">焦距调整:</div>
                <div style="width:100%;margin: 0 0 0 15%">
                    <el-button size='mini' circle class="button-o" @click="operation(10)"><img
                            src="../../assets/nearfocal.png" class="o-img" /></el-button>
                    <el-button size='mini' circle class="button-o" @click="operation(-1)"><img
                            src="../../assets/stop.png" class="o-img" /></el-button>
                    <el-button size='mini' circle class="button-o" @click="operation(11)"><img
                            src="../../assets/farfocal.png" class="o-img" /></el-button>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
    import EZUIKit from "ezuikit-js";

    export default {
        name: "HelloWorld",
        props: {
            msg: String
        },
        data() {
            return {
                treeData: [{
                    label: '球机',
                    children: []
                }, {
                    label: '枪机',
                    children: []
                }],
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                AccessToken: '',
                deviceSerial: '',
                updateKey: false,
                ChannelList: [

                ],
                radioSelected: '3',
                channelCount: 0,
            };
        },
        watch: {
            ChannelList: function () {
                this.$nextTick(function () {
                    /*现在数据已经渲染完毕*/
                })
            }
        },
        mounted() {
            // this.loadCamera();
        },
        created() {
            this.getAccessToken();
            this.$notify({
                title: '提示',
                message: '请选择视频通道',
                duration: 2000
            });
            setTimeout(() => {
                this.loadCamera(3);
            }, 2000);
        },
        methods: {
            handleNodeClick(data) {
                var channelNum = data.label.replace("号通道", "");
                document.getElementById("video-container").innerHTML = "";
                this.radioSelected = data.label.replace("号通道", "");
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.loadCamera(channelNum);
                setTimeout(() => {
                    loading.close();
                }, 2000);

            },
            getAccessToken() {    //萤石云访问摄像头token获取
                var _this = this;
                var httpRequest = new XMLHttpRequest();//第一步：创建需要的对象
                httpRequest.open('POST', 'https://open.ys7.com/api/lapp/token/get?appKey=88e7935e35b445e29749451afa436d7b&appSecret=c6e3014b4f4bad54a12e5bf512770f4d', true); //第二步：打开连接
                httpRequest.setRequestHeader("Content-type", "application/x-www-form-urlencoded");//设置请求头 注：post方式必须设置请求头（在建立连接后设置请求头）
                httpRequest.send('name=teswe&ee=ef');//发送请求 将情头体写在send中
                /**
                 * 获取数据后的处理程序
                 */
                httpRequest.onreadystatechange = function () {//请求后的回调接口，可将请求成功后要执行的程序写在其中
                    if (httpRequest.readyState == 4 && httpRequest.status == 200) {//验证请求是否发送成功
                        var json_token = httpRequest.responseText;//获取到服务端返回的数据
                        _this.AccessToken = JSON.parse(json_token).data.accessToken;
                        var DeviceLink = 'https://open.ys7.com/api/lapp/device/list?accessToken=' + _this.AccessToken + '';
                        httpRequest.open('POST', DeviceLink, true); //第二步：打开连接
                        httpRequest.setRequestHeader("Content-type", "application/x-www-form-urlencoded");//设置请求头 注：post方式必须设置请求头（在建立连接后设置请求头）
                        httpRequest.send('name=teswe&ee=ef');//发送请求 将情头体写在send中
                        /**
                         * 获取数据后的处理程序
                         */
                        httpRequest.onreadystatechange = function () {//请求后的回调接口，可将请求成功后要执行的程序写在其中
                            if (httpRequest.readyState == 4 && httpRequest.status == 200) {//验证请求是否发送成功
                                // var json_device = httpRequest.responseText;//获取到服务端返回的数据
                                _this.deviceSerial = 'G07201215';
                                var ChannelLink = 'https://open.ys7.com/api/lapp/device/camera/list?accessToken=' + _this.AccessToken + '&deviceSerial=' + _this.deviceSerial + '';
                                httpRequest.open('POST', ChannelLink, true); //第二步：打开连接
                                httpRequest.setRequestHeader("Content-type", "application/x-www-form-urlencoded");//设置请求头 注：post方式必须设置请求头（在建立连接后设置请求头）
                                httpRequest.send('name=teswe&ee=ef');//发送请求 将情头体写在send中
                                /**
                                 * 获取数据后的处理程序
                                 */
                                httpRequest.onreadystatechange = function () {//请求后的回调接口，可将请求成功后要执行的程序写在其中
                                    if (httpRequest.readyState == 4 && httpRequest.status == 200) {//验证请求是否发送成功
                                        var json_channel = httpRequest.responseText;//获取到服务端返回的数据
                                        var jsonArray = JSON.parse(json_channel).data;
                                        _this.channelCount = jsonArray.length;
                                        for (var i = 0; i < jsonArray.length; i++) {
                                            var obj = {};
                                            obj.channelName = jsonArray[i].channelName;
                                            obj.channelNo = jsonArray[i].channelNo;
                                            obj.status = jsonArray[i].status;
                                            obj.devType = jsonArray[i].devType;
                                            if (obj.status != -1)
                                                _this.ChannelList.push(obj);
                                        }
                                        for (i = 0; i < _this.ChannelList.length; i++)
                                            for (var j = i + 1; j < _this.ChannelList.length; j++) {
                                                if (_this.ChannelList[i].channelNo > _this.ChannelList[j].channelNo) {
                                                    obj = _this.ChannelList[i];
                                                    _this.ChannelList[i] = _this.ChannelList[j];
                                                    _this.ChannelList[j] = obj;
                                                }
                                            }
                                        for (i = 0; i < _this.ChannelList.length; i++) {
                                            obj = {};
                                            obj.label = _this.ChannelList[i].channelNo + '号通道';
                                            if (_this.ChannelList[i].devType == 'DS-2DC6420IW-A') {
                                                _this.treeData[0].children.push(obj);
                                            }
                                            else {
                                                _this.treeData[1].children.push(obj);
                                            }
                                        }
                                    }
                                };
                            }
                        };
                    }
                };
            },
            loadCamera(channelNum) {
                window.console.group("mounted 组件挂载完毕状态===============》");
                var player = new EZUIKit.EZUIKitPlayer({
                    autoplay: true,
                    id: "video-container",
                    accessToken: this.AccessToken,
                    url: "ezopen://TGQKMDF@open.ys7.com/G07201215/" + channelNum + ".hd.live",
                    template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
                    // 视频上方头部控件
                    header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
                    plugin: ['talk'],                       // 加载插件，talk-对讲
                    // 视频下方底部控件
                    footer: ["talk", "broadcast", "hd", "fullScreen"],
                    // 如果template参数不为simple,该字段将被覆盖
                    audio: 1,
                    // 是否默认开启声音 0 - 关闭 1 - 开启
                    openSoundCallBack: data => window.console.log("开启声音回调", data),
                    closeSoundCallBack: data => window.console.log("关闭声音回调", data),
                    startSaveCallBack: data => window.console.log("开始录像回调", data),
                    stopSaveCallBack: data => window.console.log("录像回调", data),
                    capturePictureCallBack: data => window.console.log("截图成功回调", data),
                    fullScreenCallBack: data => window.console.log("全屏回调", data),
                    getOSDTimeCallBack: data => window.console.log("获取OSDTime回调", data),
                    width: 800,
                    height: 400
                });
                setTimeout(() => {
                    player.stop(); // 方法调用示例，10秒后关闭视频
                }, 30000)
            },
            operation(operationId) {
                var _this = this;
                var httpRequest = new XMLHttpRequest();//第一步：创建需要的对象
                var startChannelLink = 'https://open.ys7.com/api/lapp/device/ptz/start?accessToken=' + _this.AccessToken + '&deviceSerial=' + _this.deviceSerial + '&channelNo=' + _this.radioSelected + '&direction=' + operationId + '&speed=0';
                var stopChannelLink = 'https://open.ys7.com/api/lapp/device/ptz/stop?accessToken=' + _this.AccessToken + '&deviceSerial=' + _this.deviceSerial + '&channelNo=' + _this.radioSelected + '&direction=' + operationId + '';
                httpRequest.open('POST', startChannelLink, true); //第二步：打开连接
                httpRequest.setRequestHeader("Content-type", "application/x-www-form-urlencoded");//设置请求头 注：post方式必须设置请求头（在建立连接后设置请求头）
                httpRequest.send('name=teswe&ee=ef');//发送请求 将情头体写在send中
                /**
                 * 获取数据后的处理程序
                 */
                httpRequest.onreadystatechange = function () {//请求后的回调接口，可将请求成功后要执行的程序写在其中

                    if (httpRequest.readyState == 4 && httpRequest.status == 200) {//验证请求是否发送成功
                        setTimeout(() => {
                            httpRequest.open('POST', stopChannelLink, true); //第二步：打开连接
                            httpRequest.setRequestHeader("Content-type", "application/x-www-form-urlencoded");//设置请求头 注：post方式必须设置请求头（在建立连接后设置请求头）
                            httpRequest.send('name=teswe&ee=ef');//发送请求 将情头体写在send中
                            httpRequest.onreadystatechange = function () {//请求后的回调接口，可将请求成功后要执行的程序写在其中
                                if (httpRequest.readyState == 4 && httpRequest.status == 200) {//验证请求是否发送成功
                                    // window.console.log('ok!')
                                }
                                else {
                                    window.console.log('failed!')
                                }
                            }
                        }, 100)
                    }
                    else {
                        window.console.log('failed!')
                    }
                };

            }
        }
    };
</script>
<style>
    .button-d {
        margin-left: 10px;
        margin-top: 5px;
        width: 37px;
        height: 37px;
    }

    .button-o {
        margin-left: 10px;
        margin-top: 5px;
        width: 40px;
        height: 40px;
    }

    .o-img {
        width: 20px;
        height: 20px
    }

    .d-img {
        width: 14px;
        height: 14px
    }
</style>